.App {
  text-align: center;
}

.App-header {
  font-family: "Geologica";
  background: linear-gradient(#282c34, #3d4150, #282c34);
  border-radius: 0 0 0 0;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.links {
  display: grid;
  grid-template-areas: "a a" "a a";
  gap: 5px;
  margin-bottom: 100px;
}

a {
  text-decoration: none;
  color: white;
}

#github-link {
  color: rgb(183, 241, 241);
  padding: 0.5em;
  border-radius: 0.7em;
  width: fit-content;
  margin: 5px;
  font-size: 0.6em;
  font-weight: 100;
}

#github-link::after {
  content: ": Zach Mason";
  color: greenyellow;
}

.home-links {
  border-bottom: rgb(233, 89, 23) solid 2px;
  border-right: rgb(233, 111, 54) solid 3px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 2px 2px rgb(61, 30, 15);
  background-color: rgb(245, 148, 103);
  padding: 0.5em;
  min-width: 195px;
}

#return-home {
  padding: 0.5em;
  border-radius: 5px;
  border: white solid 1px;
}

.sidebar-menu {
  border-color: red;
}

.about-paragraph {
  max-width: 75%;
  padding: 30px;
  margin: auto;
  font-size: 1em;
  text-align: start;
}

.help-container {
  position: absolute;
  z-index: 7;
  left: 87%;
  bottom: 85px;
}

.help-paragraph {
  padding: 15px;
  position: fixed;
  max-height: 520px;
  width: 80%;
  background-color: aliceblue;
  border: rgb(236, 150, 111) 3px solid;
  color: black;
  z-index: 6;
  top: 15px;
  left: 5%;
  margin: auto;
  overflow-y: scroll;
  text-align: start;
}

.help-tool-button {
  color: white;
  background: none;
  border-radius: 50%;
  border: rgb(236, 150, 111) solid 2px;
  width: 1.4em;
  height: 1.4em;
  position: fixed;
  font-size: 1.2em;
  color: #282c34;
  -webkit-text-stroke-color: rgb(245, 148, 103);
  -webkit-text-stroke-width: 0.03em;
  background-color: #282c34;
  cursor: pointer;
  z-index: 8;
  left: 87%;
  bottom: 85px;
}

.logo-container {
  --deltaLeft: 15px;
  font-family: "Geologica";
  position: relative;
  width: 255px;
  height: 69px;
  margin: 25px auto;
  border: greenyellow 3px solid;
  border-radius: 15px;
}

.logo-container h3 {
  font-size: 25px;
  color: greenyellow;
}

#delta {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 33px;
  position: absolute;
  margin: 0;
  left: var(--deltaLeft);
  background-color: #282c34;
  z-index: 2;
  height: 30px;
}

.key-bar {
  width: 8px;
  height: 39px;
  position: absolute;
  left: calc(var(--deltaLeft) + 6px);
  top: 20px;
  background-color: greenyellow;
  margin: 0;
}

#kappa {
  font-weight: bold;
  font-size: 40px;
  line-height: 0px;
  position: absolute;
  margin: 0;
  top: 46px;
  left: calc(var(--deltaLeft) + 4px);
}

#didakey {
  padding: 6px 5px 16px 50px;
  line-height: 1;
  font-weight: bold;
  font-size: 48px;
  position: relative;
  margin: 0;
}

/*Alphabet Animations */

/* animation: (name) (duration) (timing-function (linear etc)) (delay) (iteration count) (direction);*/
/* animation-fill-mode is separate*/

:root {
  --random-end: 35%;
}

.letter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  border: 1px rgb(1, 93, 105) solid;
  background-color: #191c24;
  height: 75vh;
  width: 75%;
  margin: 0 auto;
  overflow: hidden;
}

.game-title {
  text-align: center;
}

@keyframes move {
  0% {
    bottom: -55px;
  }
  100% {
    left: var(--random-end);
    bottom: 700px;
  }
}

#canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

/*////////*/

@media only screen and (max-width: 525px) {
  .home-links {
    min-width: 125px;
  }
}

@media only screen and (max-width: 280px) {
  .home-links {
    min-width: 105px;
  }
}
