.pick-verse-menu {
  position: relative;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  max-height: 800px;
  margin: auto;
}

.pick-component {
  max-width: 400px;
  margin: 50px auto 0.2em;
  position: relative;
}

.drop-lists {
  display: flex;
  max-width: 100px;
  width: 25%;
  color: rgb(0, 0, 0);
  border: solid 1px rgb(206, 206, 206);
}

.drop-lists button {
  text-align: center;
  border: none;
  cursor: pointer;

  background-color: whitesmoke;
  width: 100%;
  font-size: 1em;
}
.lists-container {
  visibility: hidden;
  display: flex;
  justify-self: center;
  width: 75%;
  z-index: 1;

  position: absolute;
}

.false {
  visibility: hidden;
}

.true {
  visibility: visible;
}

.lists {
  background-color: rgb(241, 237, 230);
  color: black;
  width: 45%;
  font-size: 14px;
  text-align: center;
  height: 200px;
  overflow: scroll;
}

.list-option {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 7px;
  padding-top: 7%;
}

.selected {
  background-color: rgb(16, 185, 45);
  color: white;
}

.go-button {
  font-weight: bolder;
  color: white;
  padding: 9px 19px;
  border: none;
  background-color: rgb(16, 185, 45);
  cursor: pointer;
  width: 25%;
}

.go-button:active {
  background-color: rgb(10, 116, 28);
}

.nav-buttons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: auto;
  padding-top: 10px;
  width: 200px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f0e9;
}

::-webkit-scrollbar-thumb {
  background: rgb(20, 250, 59);
}

@media only screen and (max-width: 540px) {
  .pick-component {
    justify-content: center;
  }
}
