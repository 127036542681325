.verse-sentence {
  width: 90%;
  text-align: center;

  margin-left: 1em;
}

.verse-component {
  background-color: #282c34;
  display: block;
  text-align: center;
  overflow-y: scroll;
  height: 100dvh;
}

.verse-word {
  display: inline-block;
  position: relative;
  top: 30px;
  height: 55px;
  margin: 0px 1px;
  padding: 0px 2.5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.verse-word p {
  position: relative;
  bottom: 30px;
}

.verse-word .indicator {
  position: relative;
  bottom: 15px;
}

.verse-word-highlight-correct {
  display: inline-block;
  position: relative;
  top: 30px;
  height: 55px;
  margin-right: 2px;
  padding: 0px 2.5px;
  /* background-color: green; */
  border-radius: 5%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.off-click {
  height: 100%;
  width: 100%;
}

.off-click-enabled {
}

.da {
  background-color: rgb(3, 187, 3);
}

.na {
  background-color: rgb(86, 175, 182);
}

.conj {
  background-color: rgb(218, 99, 3);
}

.prep {
  background-color: rgb(138, 135, 0);
}

.pron {
  background-color: rgb(160, 90, 192);
}

.vrb {
  background-color: rgb(59, 112, 209);
}

.prt {
  background-color: rgb(204, 63, 117);
}

.adv {
  background-color: rgb(65, 146, 112);
}

/*
 const styleMap = {
    "definite article": "da",
    Conjunction: "conj",
    Preposition: "prep",
    "Noun and Adjective": "na",
    Pronoun: "pron",
    Verb: "vrb",
    Particle: "prt",
    Adverb: "adv",
  };
*/
.verse-word-highlight-correct p {
  position: relative;
  bottom: 30px;
}

.verse-word-highlight-odd p {
  position: relative;
  bottom: 30px;
}

.verse-word-highlight-odd .indicator {
  position: relative;
  bottom: 15px;
}

.verse-word-highlight-odd .indicator-highlight-odd {
  position: relative;
  bottom: 15px;
}

.indicator-highlight-odd {
  font-size: small;
  position: relative;
  bottom: 15px;
  height: 0px;
  color: white;
  opacity: 1;
}

.verse-word-highlight-wrong p {
  position: relative;
  bottom: 30px;
}

.verse-word-highlight-wrong .indicator {
  position: relative;
  bottom: 15px;
}
.verse-word-highlight-wrong .indicator-highlight-wrong {
  position: relative;
  bottom: 14px;
}

.verse-word-highlight-correct .indicator-highlight-correct {
  position: relative;
  bottom: 14px;
}

.verse-word-highlight-wrong {
  display: inline-block;
  position: relative;
  top: 30px;
  height: 55px;
  margin-right: 2px;
  padding: 0px 2.5px;
  background-color: rgb(255, 0, 0);
  border-radius: 5%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.verse-word-highlight-odd {
  display: inline-block;
  position: relative;
  top: 30px;
  height: 55px;
  margin-right: 2px;
  padding: 0px 2.5px;
  background-color: goldenrod;
  border-radius: 5%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.verse-word:hover {
  cursor: pointer;
}

.indicator {
  font-size: small;
  position: relative;
  bottom: 15px;
  height: 0px;
  color: white;
  visibility: hidden;
}

.indicator-highlight-correct {
  font-size: small;
  position: relative;
  bottom: 15px;
  height: 0px;
  color: white;
  visibility: visible;
}

.indicator-highlight-wrong {
  font-size: small;
  position: relative;
  bottom: 15px;
  height: 0px;
  color: white;
  opacity: 1;
}

.inner-word {
}

/* @media (orientation: landscape) {
  .verse-word-highlight-correct .indicator {
    bottom: 0px;
    top: 18px;
  }
  .verse-word-highlight-correct .indicator-highlight-correct {
    bottom: 0px;
    top: -11px;
  }

  .verse-word-highlight-wrong {
    height: 55px;
    padding: 0px 2.5px;
  }

  .verse-word-highlight-wrong .indicator {
    bottom: 0px;
    top: 18px;
  }

  .verse-word-highlight-wrong .indicator-highlight-wrong {
    bottom: 0px;
    top: -11px;
  }
} */

@media only screen and (max-height: 670px) {
  .indicator-highlight-correct {
    top: 21px;
  }

  .verse-word-highlight-correct {
    height: 55px;
  }

  .indicator-highlight-wrong {
    top: 21px;
  }

  .verse-word-highlight-wrong {
    height: 55px;
  }
}

@media only screen and (max-height: 425px) {
  .indicator-highlight-correct {
    top: 10px;
  }

  .verse-word-highlight-correct {
    height: 40px;
  }

  .indicator-highlight-wrong {
    top: 10px;
  }

  .verse-word-highlight-wrong {
    height: 40px;
  }
}

@media only screen and (max-width: 555px) {
  .verse-word {
    height: 45px;
    padding: 0px 2.5px;
    font-size: larger;
  }

  .verse-word-highlight-correct {
    height: 48px;
    padding: 0px 2.5px;
    font-size: larger;
  }

  .verse-word-highlight-correct .indicator {
    bottom: 0px;
    top: 18px;
  }
  .verse-word-highlight-correct .indicator-highlight-correct {
    bottom: 0px;
    top: 18px;
  }

  .verse-word-highlight-wrong {
    height: 48px;
    padding: 0px 2.5px;
    font-size: larger;
  }

  .verse-word-highlight-wrong .indicator {
    bottom: 0px;
    top: 18px;
  }

  .verse-word-highlight-wrong .indicator-highlight-wrong {
    bottom: 0px;
    top: 18px;
  }

  .verse-word-highlight-odd .indicator-highlight-odd {
    position: relative;
    top: 18px;
  }
  .verse-word-highlight-odd .indicator {
    bottom: 0px;
    top: 18px;
  }
  .verse-word-highlight-odd {
    height: 48px;
    padding: 0px 2.5px;
    font-size: larger;
  }

  .submit-button {
    width: 15em;
    margin-bottom: 2em;
  }
}

.passage {
  text-align: end;
  font-size: 0.9em;
  padding-right: 4px;
  margin-top: 5px;
  margin-bottom: 0px;
  opacity: 0.75;
  margin-left: 15px;
  font-style: italic;
}

.nav-bar {
  background-color: rgb(18, 117, 5);
  width: 100%;
}

.nav-options {
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 65px;
}

.option-nav {
  cursor: pointer;
  border-style: none;
  background-color: rgb(18, 117, 5);
  color: white;
  font-size: medium;
  height: 65px;
}

.option-nav-highlighted {
  background-color: rgb(43, 255, 15);
  color: #282c34;
  border: none;
  font-size: medium;
  height: 65px;
}
